// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
body > * {
  width: 100%;
}

.flex-grow {
  flex-grow: 1;
}
.bg-yellow {
  background-color: #fae29a;
}
.padding-top-2rem {
  padding-top: 2rem;
}

.padding-1rem {
  padding: 1rem;
}
.padding-2rem {
  padding: 2rem;
}

.display-5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.1;
}
.display-6 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}
.display-6-light {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
}

.text-light-grey {
  color: lightgrey;
}