// 
// 
// footers.scss
//
//

footer.customFooter {
    align-self: flex-end;
    min-height: 72px;
}

@include media-breakpoint-up(md) {
    footer {
        padding: 6rem 0;
    }
}

@include media-breakpoint-up(lg) {
    footer {
        padding: 7.5rem 0;
    }
}

@include media-breakpoint-up(md){
    .footer-1{
        hr{
            margin-top: $spacer*6;
        }
    }
}